<template>
  <div class="grid">
    <div class="col-12 xl:col-12">
      <div class="card">
        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->

        <ProjectInfo />

        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <BodyHeader />
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- Accordion Tab ----------------------------------------------------------------------------------------------------------------------------------->
        <div class="grid" style="padding-top: 20px; justify-content: center">
          <div
            class="col-12 xl:col-12 sm:col-12 md:col-12 .col-12"
            style="text-align: center"
          >
            <div>
              <div
                class="grid"
                style="
                  box-sizing: border-box;
                  background: rgb(255, 255, 255);
                  border: 2px solid rgb(245, 246, 247);
                  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
                  border-radius: 18px;
                  justify-content: center;
                "
              >
                <div
                  class="col-12 xl:col-5 md:col-12 lg:col-12 sm:col-12 .col-12 .col-sm-12"
                  style="margin-right: 6px"
                >
                  <ErrorBar />
                </div>
                <div
                  style="align-self: center; align-items: center"
                  class="flex"
                >
                  <div
                    style="
                      width: 13px;
                      height: 13px;
                      border-radius: 50%;
                      background: #04b4ff;
                      margin-right: 10px;
                    "
                  ></div>
                  <div
                    style="
                      font-style: normal;
                      font-weight: 900;
                      font-size: 15px;
                      line-height: 23px;
                      color: #585857;
                      padding-right: 10px;
                    "
                  >
                    <span>Controle</span>
                  </div>
                  <Image src="/images/betaIcon.svg" alt="Image" width="20" />
                  <div
                    style="
                      font-style: normal;
                      font-weight: 900;
                      font-size: 15px;
                      line-height: 23px;
                      color: #585857;
                    "
                  >
                    <span>Produto</span>
                  </div>
                </div>
                <div class="col-6 xl:col-1 md:col-12 lg:col-12 sm:col-12">
                  <Button
                    label="Filtrar"
                    icon="pi pi-filter"
                    @click="openModal"
                  />
                  <Dialog
                    header="Filtrar"
                    v-model:visible="displayModal"
                    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
                    style="width: 55vw; text-align: center"
                    :modal="true"
                  >
                    <span
                      style="
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 23px;
                        color: #585857;
                      "
                      >Selecione as variáveis que deseja avaliar no
                      gráfico.</span
                    >
                    <div class="grid cardFilter">
                      <div
                        class="field-radiobutton col-12 xl:col-5 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="Controle"
                          name="Controle"
                          value="Controle"
                          v-model="filterType"
                        />
                        <label
                          for="city1"
                          style="display: flex; align-items: center"
                          class="city filter-text"
                        >
                          <Image
                            src="/images/bacteria.svg"
                            alt="Image"
                            width="45"
                            style="padding-right: 5px"
                          /><span style="margin: 22px">Bactérias</span></label
                        >
                      </div>
                      <div
                        class="field-radiobutton col-12 xl:col-5 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="Vegetativo"
                          name="Vegetativo"
                          value="Vegetativo"
                          v-model="filterType"
                        />
                        <label
                          for="city2"
                          style="display: flex; align-items: center"
                          class="city filter-text"
                        >
                          <Image
                            src="/images/fungus.svg"
                            alt="Image"
                            width="55"
                            style="padding-right: 5px"
                          /><span style="margin: 22px">Fungos</span></label
                        >
                      </div>
                    </div>

                    <span
                      style="
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 23px;
                        color: #585857;
                      "
                      >Selecione o estágio.</span
                    >
                    <div
                      v-if="project.typeProject === '01'"
                      class="grid cardFilter"
                    >
                      <div
                        class="field-radiobutton col-12 xl:col-5 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="Vegetativo"
                          name="Vegetativo"
                          value="Vegetativo"
                          v-model="filterType2"
                        />
                        <label for="city1" class="city filter-text">
                          <Image
                            src="/images/logoVeg.svg"
                            alt="Image"
                            width="30"
                            style="padding-right: 10px"
                          />Vegetativo</label
                        >
                      </div>
                      <div
                        class="field-radiobutton col-12 xl:col-4 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="Florescimento"
                          name="Florescimento"
                          value="Florescimento"
                          v-model="filterType2"
                        />
                        <label for="city2" class="city filter-text">
                          <Image
                            src="/images/florIcon.svg"
                            alt="Image"
                            width="30"
                            style="padding-right: 10px"
                          />Reprodutivo</label
                        >
                      </div>
                    </div>

                    <div v-else class="grid cardFilter">
                      <div
                        class="field-radiobutton col-12 xl:col-4 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="Vegetativo"
                          name="Vegetativo"
                          value="Vegetativo"
                          v-model="filterType2"
                        />
                        <label for="city1" class="city filter-text">
                          <Image
                            src="/images/logoVeg.svg"
                            alt="Image"
                            width="30"
                            style="padding-right: 10px"
                          />Vegetativo</label
                        >
                      </div>
                    </div>
                    <Button label="Atualizar" />
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
          <!-- Accordion Tab ----------------------------------------------------------------------------------------------------------------------------------->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectInfo from "@/components/ProjectInfo.vue";
import BodyHeader from "@/components/BetaDiversity/BodyHeader.vue";
import ErrorBar from "../components/BetaDiversity/ErrorBar.vue";
import { useProjectsStore } from "@/store/projects";

export default {
  data() {
    return {
      projectsStore: useProjectsStore(),
      selectedType: null,
      loading: true,
      displayModal: false,
      filterarea: "AllAreas",
      filterType: "Controle",
      filterType2: "Vegetativo",
    };
  },
  computed: {
    project() {
      var projectId = this.$route.params.projectId;
      return this.projectsStore.project(projectId);
    },
  },
  mounted() {},
  beforeUnmount() {},
  created() {},
  methods: {
    openModal() {
      this.displayModal = true;
    },
    closeModal() {
      this.displayModal = false;
    },
  },
  components: {
    ProjectInfo: ProjectInfo,
    BodyHeader: BodyHeader,
    ErrorBar: ErrorBar,
  },
};
</script>

<style></style>
