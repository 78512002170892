export function generateDataSource() {
  let ds = [
{"x1":3, "x2":-3, "y1":0.4, "y2":3.9},
{"x1":3.1, "x2":-2, "y1":1.4, "y2":2.9},
{"x1":5, "x2":-2.3, "y1":3.4, "y2":3},
{"x1":4.3, "x2":-3.3, "y1":2.2, "y2":4.4},
{"x1":4.2, "x2":-4, "y1":4.4, "y2":5.5},
{"x1":5.1, "x2":-4.5, "y1":3.1, "y2":5.3},
{"x1":6.3, "x2":-3.2, "y1":3, "y2":4.5},
{"x1":6, "x2":-4.4, "y1":2, "y2":6},
{"x1":6.1, "x2":-.1, "y1":1, "y2":5},
];
  return ds;
}
